async function sendEmail(secureToken) {
  const formData = new FormData(document.querySelector('form'));
  const data = {
    firstname: formData.get('firstname'),
    lastname: formData.get('lastname'),
    email: formData.get('email'),
    message: formData.get('message')
  };
  const errorMessageElement = document.getElementById('error-message');
    if (!data.firstname || !data.lastname || !data.email || !data.message) {
      errorMessageElement.textContent = 'Please fill in all the required fields.';
      return;
    }
    errorMessageElement.textContent = 'Thank you for the email ' + data.firstname + '!';
  return fetch('https://parligator.com/sendemail', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + secureToken
    },
  })
  .then(response => response.status)
  .catch(error => console.error(error)); 
}

async function validateSecureToken(secureToken) { 
  console.log('validateSecureToken secureToken',secureToken); 
  try { 
    const response = await fetch('https://parligator.com/validate', { 
      method: 'POST', 
      body: JSON.stringify({secureToken: secureToken}), 
      headers: { 'Content-Type': 'application/json' }, 
    }); 
    if (response.status === 200) { 
      console.log('valid response - 200') 
    } else { 
      console.log('invalid response -', response.status) 
    } 
    return response; 
  } 
  catch (error) { 
    console.error(error); 
  } 
}

async function protected() { 
  try { 
    const response = await fetch('https://parligator.com/protected', { 
      method: 'GET', 
      headers: { 'Content-Type': 'application/json' }, 
    }); 
    console.log('protected status:',response.status); 
    if (response.status !== 200) { 
      return false; 
    }
    const token = await response.json(); 
    console.log('LocalStorage',token.encryptedUserTokens); 
    localStorage.setItem('secureToken',token.encryptedUserTokens); 
    return token.encryptedUserTokens; 
  } 
  catch (error) { 
    console.error(error); 
  } 
}

async function main() {
  let secureToken = localStorage.getItem('secureToken');
  if (!secureToken) {
    console.log('No secureToken found in localStorage');
    secureToken = await protected();
    console.log('main secureToken:',secureToken);
  }
  try {
    console.log('trying to send an email')
    res = await sendEmail(secureToken);
    if (res === 200) {
      console.log('email sent')
    } else if (res === 401) {
      console.log('email not sent - 401')
      if (localStorage.getItem('secureToken')) {
        localStorage.removeItem('secureToken');
        await main();
      }
    } else {
      console.log('email not sent')
    }
  }
  catch (error) {
    console.error(error);
  }
}